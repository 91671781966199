'use client';
import { Image } from '@bw/elements';
import { Apple, Loader, PlayBtn, Spotify } from '@bw/elements/icons';
import { PodcastItem } from '@bw/external-apis/shared';
import { Fragment, useEffect, useState } from 'react';
import { formatDate } from '../../helpers/date-convert';
import { formatTime } from '../../helpers/time-convert';
import { podcastBanner, rickFoxImg } from '../../stock-images';
import { useAppContext } from '../form-stepper/StepperProvider';
import PodcastPlayer from '../podcast-player/PodcastPlayer';
import styles from './PodcastNavigator.module.scss';

type AnnouncementHeightProps = Record<string, string>;
type PodcastNavigatorProps = {
  podcastItems: PodcastItem[];
};

export function PodcastNavigator({ podcastItems }: PodcastNavigatorProps) {
	const [podcastData, setPodcastData] = useState<any>([]);
	const [selectedPodcast, setSelectedPodcast] = useState<any>();
	const { setPodcastPopupDisplay } = useAppContext();
	const { announcementBannerHeight } = useAppContext();

	const [loading, setLoading] = useState(true);

	const getPodcastData = async () => {
		try {
			setPodcastData(podcastItems);
			setLoading(false); // Set loading to false once data is received
		} catch (error) {
			setLoading(false); // Set loading to false in case of an error
		}
	};

	useEffect(() => {
		getPodcastData();
	}, []);

	const handlePlayLatestEpisode = () => {
		setSelectedPodcast(podcastData[0]);
		setPodcastPopupDisplay(true);
	};

	const handlePlayEpisode = (podcast) => {
		setSelectedPodcast(podcast);
		setPodcastPopupDisplay(true);
	};

	const announcementHeight: AnnouncementHeightProps = {
		'--announcement-banner-height': `${announcementBannerHeight}px`,
	};
	return (
		<>
			<div className={styles['podcastCard']}>
				{loading ? (
					// Display a loader while data is being fetched
					<div className="is__pageloader is__fill">
						<figure>
							<Loader width={100} height={100} />
						</figure>
					</div>
				) : (
					podcastData.length > 0 && (
						<div className="container">
							<div
								className="podcastCard__wrapper"
								data-aos="fade-up"
								style={announcementHeight}
							>
								<div key={0} className="podcastCard__latest" data-aos="fade-up">
									{/* Assuming you only want to display the first podcast */}
									<figure className="latest__card-img" data-aos="fade-up">
										<Image
											alt="podcast image"
											src={podcastBanner}
											width={1000}
											height={1000}
										/>
									</figure>
									<h1 data-aos="fade-up">Insurance the Brightway (ITB)</h1>
									<p data-aos="fade-up">
										Listen to industry leaders talk about the brighter way. This
										podcast focuses on sales strategies and tips, the newest
										tech, and confidence in difficult markets. Hear interviews
										with industry experts and general InsuRants - things that
										unnecessarily complicate our industry and what to do about
										them. This podcast paves the way to help you
										#WinAtInsurance.
									</p>
									<div className="podcastCard__latest-wrapper">
										<button
											className="play__latest"
											onClick={handlePlayLatestEpisode}
											data-aos="fade-up"
										>
											<PlayBtn
												aria-hidden
												className="svg"
												width={141}
												height={286}
											/>
											Play the latest episode
										</button>
										<div className="podcast__subscribe">
											<p data-aos="fade-up">Subscribe</p>
											<ul>
												<li data-aos="fade-up">
													<a
														href=" https://open.spotify.com/show/3OwoK1YUyWdBwoRo7xCbqk"
														title="spotify"
														target="_blank"
														aria-label="Spotify (opens in a new tab)"
													>
														<Spotify aria-hidden />
													</a>
												</li>
												<li data-aos="fade-up">
													<a
														href=" https://podcasts.apple.com/us/podcast/insurance-the-brightway/id1683626358"
														title="apple music"
														target="_blank"
														aria-label="Apple Music (opens in a new tab)"
													>
														<Apple aria-hidden />
													</a>
												</li>
											</ul>
										</div>
										<div className="pocast__artist">
											<figure data-aos="fade-up">
												<Image
													src={rickFoxImg}
													alt="Rick Fox"
													width={120}
													height={120}
												/>
											</figure>
											<article>
												<span data-aos="fade-up">
													{podcastData[0]?.artist},
												</span>
												<span data-aos="fade-up">Chief Revenue Officer</span>
												<p data-aos="fade-up">Host of the ITB Podcast</p>
											</article>
										</div>
									</div>
								</div>
								<div
									className="podcastCard__listing"
									data-aos="fade-up"
									data-aos-delay="100"
								>
									<h2 className="listing_header" data-aos="fade-up">
										Episodes
									</h2>
									{podcastData?.map((podcast) => (
										<Fragment key={`${podcast.title}`}>
											<div
												className="podcastCard__item"
												data-aos="fade-up"
												data-aos-delay="100"
											>
												<p className="episod__time" data-aos="fade-up">
													{formatDate(podcast?.published_at)}
												</p>
												<h3 className="episod__title" data-aos="fade-up">
													{podcast?.title}
												</h3>
												<p
													className="episod__dicription"
													dangerouslySetInnerHTML={{
														__html: podcast?.description.replace(
															/<a(.*?)href="(.*?)"(.*?)>/g,
															'<a$1href="$2"$3 target="_blank" rel="noopener noreferrer">'
														),
													}}
													data-aos="fade-up"
												/>

												<button
													className="episod__btn"
													onClick={() => handlePlayEpisode(podcast)}
													data-aos="fade-up"
												>
													<PlayBtn
														className="svg"
														aria-hidden
														width={16}
														height={16}
														style={{ marginRight: 8 }}
													/>
													Play <span>{formatTime(podcast?.duration)}</span>
												</button>
											</div>
										</Fragment>
									))}
								</div>
							</div>
						</div>
					)
				)}
			</div>
			{selectedPodcast && <PodcastPlayer podcastData={selectedPodcast} />}
		</>
	);
}
