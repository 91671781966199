'use client';
import { DeepBluePin, LoaderBlue, WhiteArrow } from '@bw/elements/icons';
import { AgenciesByLocationTextResult } from '@bw/external-apis';
import { Agency, getAgencySEOSlug } from '@bw/external-apis/shared';
import {
	GoogleMap,
	InfoWindow,
	Marker,
	useJsApiLoader,
} from '@react-google-maps/api';
import { getCookie, setCookie } from 'cookies-next';
import Link from 'next/link';
import {
	JSXElementConstructor,
	Key,
	KeyboardEvent,
	ReactElement,
	ReactNode,
	ReactPortal,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react';
import { GEO_CORDINATES, MAP_PIN_URL } from '../../constants';
import FormButton from '../utility/formButton/formButton';
import styles from './AgentMapComponent.module.scss';

type AgencyData = AgenciesByLocationTextResult | Agency;

export type ResultState = {
	status: 'initial' | 'zipError' | 'agentError';
};

export function AgentMapComponent(props: { googleMapsApiKey?: string }) {
	const [isMobile, setIsMobile] = useState(false);
	const [agencyData, setAgencyData] = useState<AgencyData[]>([]);
	const [activeMarker, setActiveMarker] = useState<number | null>(null);

	const handleActiveMarker = (marker: number) => {
		if (marker === activeMarker) {
			return;
		}
		setActiveMarker(marker);
	};

	const handleActiveMarkerChange = (marker: number) => {
		setActiveMarker(marker);
	};

	const updateAgencyData = (data: SetStateAction<AgencyData[]>) => {
		setAgencyData(data);
	};

	// Function to get latitude and longitude by agency id
	const getLatLngById = (id: number | null) => {
		const agency = agencyData.find((agency) => agency.id === id);

		if (agency && 'latitude' in agency) {
			return { lat: agency.latitude, lng: agency.longitude };
		}

		// Return a default location or handle the case where no agency with the given id is found
		return null;
	};

	useEffect(() => {
		// Check if window is defined to avoid server-side rendering errors
		if (typeof window !== 'undefined') {
			setIsMobile(window.innerWidth <= 769);

			const handleResize = () => {
				setIsMobile(window.innerWidth <= 769);
			};

			window.addEventListener('resize', handleResize);

			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}
	}, []);

	const centerFromList = getLatLngById(agencyData[0]?.id) ?? {
		lat: GEO_CORDINATES.LATITUDE,
		lng: GEO_CORDINATES.LONGITUDE,
	};
	const center = getLatLngById(activeMarker) ?? centerFromList;

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: props.googleMapsApiKey ?? '',
	});
	if (!isMobile) {
		return isLoaded ? (
			// Important!
			//  Always set the container height explicitly

			<div className={styles.agentMapComponent}>
				<GoogleMap
					mapContainerClassName={styles['mapContainer']}
					center={center}
					zoom={agencyData.length > 0 ? 10 : 5}
					clickableIcons={false}
				>
					{agencyData.length > 0 &&
						agencyData.map((agent) => (
							<Marker
								key={agent.id}
								position={{
									lat: +agent.latitude,
									lng: +agent.longitude,
								}}
								icon={
									activeMarker !== agent.id
										? MAP_PIN_URL.NORMAL
										: MAP_PIN_URL.ACTIVE
								}
								onClick={() => handleActiveMarker(agent.id)}
							>
								{activeMarker === agent.id ? (
									<InfoWindow
										onCloseClick={() => setActiveMarker(null)}
										position={{
											lat: +agent.latitude,
											lng: +agent.longitude,
										}}
									>
										<>
											<div className={styles.markerAgencyName}>
												{agent.marketingName}
											</div>
											<div className={styles.markerAgencyAddress}>
												{agent.address1} {agent.address2}
												<br />
												{agent.city},&nbsp;
												{agent.stateAbbreviation} {agent.postalCode}
											</div>
											<div className={`${styles.markerLink}`}>
												<Link
													href={`https://www.google.com/maps?q=${agent.latitude},${agent.longitude}`}
													title={`Google map link to ${agent.marketingName}`}
													target="_blank"
													aria-label={`Google map link to ${agent.marketingName} (opens in a new tab)`}
												>
													View on Google Maps
												</Link>
											</div>
										</>
									</InfoWindow>
								) : null}
							</Marker>
						))}
					<SearchBox
						apiKey={props.googleMapsApiKey}
						updateAgencyData={updateAgencyData}
						activeAgent={activeMarker}
						handleActiveMarkerChange={handleActiveMarkerChange}
					/>
				</GoogleMap>
			</div>
		) : (
			<div className="is__pageloader is__fill">
				<figure>
					<LoaderBlue width={100} height={100} />
				</figure>
			</div>
		);
	} else {
		return (
			<>
				<div
					className={`${styles.agentMapComponent} ${styles['agentMapComponent--NoMap']}`}
				>
					<SearchBox
						apiKey={props.googleMapsApiKey}
						activeAgent={activeMarker}
						handleActiveMarkerChange={handleActiveMarkerChange}
					/>
				</div>
			</>
		);
	}
}

const isNotCorporate = (agency: AgencyData): boolean => {
	return agency.storeNumber !== '0001';
};

function SearchBox(props: {
	updateAgencyData?: (data: any) => void;
	activeAgent: number | null;
	handleActiveMarkerChange?: (data: any) => void;
	apiKey?: string;
}) {
	const {
		handleActiveMarkerChange = (data) => {},
		updateAgencyData = (data: any) => {},
	} = props;
	const [searchText, setSearchText] = useState('');
	const [autocompleteList, setAutocompleteList] = useState<any>([]);
	const [completeList, setCompleteList] = useState<any>([]);
	const [showDropdown, setShowDropdown] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [agencyData, setAgencyData] = useState<AgencyData[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [zipcodeFetching, setZipcodeFetching] = useState<boolean>(false);
	const [noData, setNoData] = useState<boolean>(false);
	const [resultState, setResultState] = useState<ResultState>({
		status: 'initial',
	});
	const [activeDropdownItem, setActiveDropdownItem] = useState(0);
	const inputRef = useRef<HTMLInputElement>(null);
	const dropdownRef = useRef<any>(null);
	const activeAgentRef = useRef<HTMLDivElement>(null);
	const searchBoxContainerRef = useRef<HTMLDivElement>(null);
	const resultSectionRef = useRef<HTMLDivElement>(null);

	const ResultErrorDisplay = ({ state }: { state: ResultState }) => {
		return (
			<>
				{state.status === 'initial' && (
					<div className={styles.resultBox} aria-live="polite">
						Please provide us with your ZIP code or type an agent name so we can
						find the nearest Brightway Agent ready to serve you.
					</div>
				)}
				{state.status === 'zipError' && (
					<div className={styles.resultBox} aria-live="polite">
						Sorry but we aren&apos;t in your location yet. Please check back in
						the future as we are growing!
					</div>
				)}
				{state.status === 'agentError' && (
					<div className={styles.resultBox} aria-live="polite">
						Agent Not Found
					</div>
				)}
			</>
		);
	};

	//onclick functions
	const handleAutocompleteItemClick = (item: any) => {
		setSearchText(item.displayName);
		setSelectedItem(item);
		setShowDropdown(false);
	};

	const handleResultBoxClick = (item: number) => {
		handleActiveMarkerChange(item);
	};

	const handleSearchChange = (event: { target: { value: any } }) => {
		const inputValue = event.target.value;
		setSearchText(inputValue);
		if (!inputValue) {
			setShowDropdown(false); // Hide the dropdown when input is empty
		} else {
			const filteredList = getAllFilteredAgents(inputValue, completeList);
			setAutocompleteList(filteredList); // Limit to the first 20 filtered items
			setShowDropdown(filteredList.length > 0);
		}

		setSelectedItem(null);
	};
	const handleButtonSubmit = (event?: { preventDefault: () => void }) => {
		event?.preventDefault();

		handleActiveMarkerChange(null);
		if (showDropdown) {
			handleAutocompleteItemClick(autocompleteList[activeDropdownItem]);
		} else if (selectedItem) {
			getAgencyDetailsById(selectedItem);
			setNoData(false);
		} else if (searchText) {
			setNoData(false);
			getAgencyDetailsBySearchText(searchText);
		} else {
			setAgencyData([]);
			updateAgencyData([]);
			setNoData(true);
		}
	};

	const handleVisitSiteClick = () => {
		setCookie('leadSourceId', '2');
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.keyCode === 40) {
			e.preventDefault();
			setActiveDropdownItem(
				activeDropdownItem < autocompleteList.length - 1
					? activeDropdownItem + 1
					: 0
			);
		} else if (e.keyCode === 38) {
			e.preventDefault();
			setActiveDropdownItem(
				activeDropdownItem < 1
					? autocompleteList.length - 1
					: activeDropdownItem - 1
			);
		}
	};

	const findLocationOnClick = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				async (position) => {
					const latitude = position.coords.latitude;
					const longitude = position.coords.longitude;
					setZipcodeFetching(true);

					try {
						const response = await fetch(
							`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${props.apiKey}`
						);

						if (!response.ok) {
							setZipcodeFetching(false);
							throw new Error(
								`Geocoding API request failed with status ${response.status}`
							);
						}

						const data = await response.json();

						if (data.results && data.results.length > 0) {
							const zipcodeComponent = data.results[0].address_components.find(
								(component: { types: string | string[] }) =>
									component.types.includes('postal_code')
							);

							if (zipcodeComponent) {
								const zipcode = zipcodeComponent.long_name;
								setSearchText(zipcode);
								setSelectedItem(null);
								setZipcodeFetching(false);
							} else {
								throw new Error('Postal code not found in geocoding response.');
							}
						} else {
							throw new Error('No results found in geocoding response.');
						}
					} catch (error) {
						console.error('Error fetching zipcode:', error);
						setZipcodeFetching(false);
					}
				},
				(error) => {
					// Handle geolocation permission denial
					if (error.code === error.PERMISSION_DENIED) {
						alert(
							'Location services permission has been declined. Kindly enable location services for this website.'
						);
						setZipcodeFetching(false);
					} else {
						console.error('Error getting current position:', error);
						setZipcodeFetching(false);
					}
				}
			);
		} else {
			alert('Geolocation is not supported in your browser.');
			setZipcodeFetching(false);
		}
	};

	//api calls

	const getAgentsData = async () => {
		try {
			const agents = (await fetch(`/api/agencies`).then((r) =>
				r.json()
			)) as Agency[];

			setAutocompleteList(agents);
			setCompleteList(agents);
		} catch (error) {
			setAutocompleteList([]);
			setCompleteList([]);
		}
	};

	const getAgencyDetailsBySearchText = async (searchText: string) => {
		setLoading(true);
		try {
			const agencies = (await fetch(
				`/api/agencies?searchText=${searchText}`
			).then((r) => r.json())) as AgenciesByLocationTextResult[];
			setAgencyData(agencies.length > 0 ? agencies : []);
			setLoading(false);
			setResultState({ status: 'zipError' });
		} catch (error) {
			setLoading(false);
			setAgencyData([]);
			setResultState({ status: 'zipError' });
		}
	};
	const getAgencyDetailsById = async (selectedItem: any) => {
		setLoading(true);
		try {
			const agencyById = await fetch(`/api/agencies/${selectedItem.id}`);
			const agentData = (await agencyById.json()) as Agency | null;

			setAgencyData(agentData ? [agentData] : []);
			setLoading(false);
			setResultState({ status: 'agentError' });
		} catch (error) {
			setLoading(false);
			setAgencyData([]);
			setResultState({ status: 'agentError' });
		}
	};

	//functions
	const getAllFilteredAgents = (inputValue: string, originalList: any[]) => {
		return originalList.filter((item: { displayName: string }) =>
			item.displayName.toLowerCase().includes(inputValue.toLowerCase())
		);
	};

	useEffect(() => {
		getAgentsData();
	}, []);

	useEffect(() => {
		updateAgencyData(agencyData);
	}, [agencyData]);

	useEffect(() => {
		// Close the dropdown when clicking outside of it
		const handleClickOutside = (event: { target: any }) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setShowDropdown(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		const visitedAgentId = getCookie('visitedAgentId');
		if (visitedAgentId) {
			const visitedAgentIndex = agencyData.findIndex(
				(agent) => agent.id === Number(visitedAgentId)
			);

			if (visitedAgentIndex !== -1) {
				const visitedAgent = agencyData[visitedAgentIndex];
				// Remove the visited agent from the original position
				agencyData.splice(visitedAgentIndex, 1);
				// Insert the visited agent at the beginning
				agencyData.unshift(visitedAgent);
				setAgencyData(agencyData);
			}
		}
	}, [agencyData]);

	useEffect(() => {
		// Scroll to the div with the id corresponding to activeAgent
		if (
			props.activeAgent &&
			activeAgentRef.current &&
			searchBoxContainerRef.current
		) {
			searchBoxContainerRef.current.scrollTo({
				behavior: 'smooth',
				top: activeAgentRef.current.offsetTop,
			});
		}
	}, [props.activeAgent]);

	useEffect(() => {
		// Focus on the input element when selectedItem changes
		if (selectedItem && inputRef.current) {
			inputRef.current.focus();
		}
	}, [selectedItem]);

	useEffect(() => {
		if (
			resultSectionRef.current &&
			typeof window !== 'undefined' &&
			window.innerWidth <= 769
		) {
			window.scrollTo({
				behavior: 'smooth',
				top: resultSectionRef.current.offsetTop,
			});
		}
	}, [agencyData]);

	return (
		<div
			className={styles.sectionContainer}
			ref={searchBoxContainerRef}
			data-lenis-prevent
		>
			<div className={styles.contentWrapper}>
				<h1 className={styles.heading}>
					Find a <span>Brightway</span> Agent
				</h1>
				<form
					id="find-an-agent-form"
					className={styles.searchBox}
					onSubmit={handleButtonSubmit}
					role="search"
				>
					<input type="hidden" name="gclid" />
					<input type="hidden" name="ga_id" />

					<div className={styles.searchDropdownContainer} ref={dropdownRef}>
						<label htmlFor="search-agent">
							Search by Agent, City, or Zip Code
						</label>
						<div className={styles.searchContainer}>
							<input
								id="search-agent"
								type="search"
								value={searchText}
								onChange={handleSearchChange}
								tabIndex={0}
								name="search-agent"
								title="Search by Agent, City, or Zip Code"
								ref={inputRef}
								aria-controls="result-section"
								onKeyDown={(e) => handleKeyDown(e)}
							/>
							<button
								title="find your location"
								type="button"
								onClick={findLocationOnClick}
								tabIndex={0}
								onKeyDown={(e) => {
									// Handle Enter key press
									if (e.key === 'Enter') {
										findLocationOnClick;
									}
								}}
							>
								{zipcodeFetching ? (
									<LoaderBlue
										className={styles.loader}
										width={200}
										height={200}
									/>
								) : (
									<>
										<DeepBluePin width={20} height={20} />
										<span className={styles.searchLocationBtn}>
											My Location
										</span>
									</>
								)}
							</button>
						</div>
						{showDropdown && (
							<ul className={styles.autocompleteItems}>
								{autocompleteList.map(
									(
										item: {
											displayName:
												| string
												| number
												| boolean
												| ReactElement<any, string | JSXElementConstructor<any>>
												| Iterable<ReactNode>
												| ReactPortal
												| null
												| undefined;
										},
										index: Key | null | undefined
									) => (
										<li
											key={index}
											onClick={() => handleAutocompleteItemClick(item)}
											className={`${styles.autocompleteItem} ${
												activeDropdownItem === index
													? styles['autocompleteItem--selected']
													: ''
											}`}
											onKeyDown={(e) => {
												// Handle Enter key press
												if (e.key === 'Enter') {
													handleAutocompleteItemClick(item);
												}
											}}
											tabIndex={0}
										>
											{item.displayName}
										</li>
									)
								)}
							</ul>
						)}
					</div>
					{noData ? <p className="error">This field is required</p> : null}
					<div className={styles.buttonContainer}>
						<FormButton
							id="search-agent"
							title="Find Agents"
							type="submit"
							onClick={handleButtonSubmit}
						/>
						{!agencyData?.length
							? null
							: !loading && (
									<span>
										<WhiteArrow width={24} height={24} />
										{agencyData.length} Results
									</span>
							  )}
					</div>
				</form>
			</div>
			{loading ? (
				<div className="is__pageloader is__center">
					<figure>
						<LoaderBlue width={100} height={100} />
					</figure>
				</div>
			) : agencyData.length !== 0 ? (
				<div
					role="region"
					id="result-section"
					aria-live="polite"
					ref={resultSectionRef}
				>
					{agencyData.map((agency, index) => (
						<div
							className={`${styles.resultBox} ${
								agency.id === props.activeAgent
									? styles['resultBox--active']
									: ''
							}`}
							key={index}
							id={agency.id?.toString()}
							ref={agency.id === props.activeAgent ? activeAgentRef : null}
							onClick={() => handleResultBoxClick(agency.id)}
							aria-live="polite"
						>
							<div className={styles.textContainer}>
								<h2>{agency.marketingName}</h2>
								{agency.distance && (
									<p className={styles.distance}>{agency.distance} mi. away</p>
								)}
								{'eligibleWritingStates' in agency && (
									<p className={styles.places}>
										Policies for: {agency.eligibleWritingStates}
									</p>
								)}
								{!agency.corporateHideAddress && (
									<address>
										{agency.address1 && (
											<>
												{agency.address1}
												<br />
											</>
										)}
										{agency.address2 && (
											<>
												{agency.address2}
												<br />
											</>
										)}
										{agency.city && <>{agency.city},&nbsp;</>}
										{agency.stateAbbreviation && (
											<>{agency.stateAbbreviation} </>
										)}
										{agency.postalCode && <>{agency.postalCode}</>}
									</address>
								)}
								<a
									className={`${styles.contact}${styles.phone}`}
									href={`tel:${agency?.phone}`}
									title={agency?.phone}
								>
									{agency?.phone}
								</a>
								<p className={styles.contact}>{agency?.email}</p>
							</div>
							<div className={styles.actionSection}>
								{isNotCorporate(agency) && (
									<div className={styles.buttonContainer}>
										<Link
											className={styles.button}
											href={getAgencySEOSlug(agency)}
											onClick={() => handleVisitSiteClick()}
										>
											Visit Site
										</Link>
									</div>
								)}
								<a
									className={styles.email}
									href={`mailto:${agency.email}`}
									title={agency.email}
								>
									Email
								</a>
							</div>
						</div>
					))}
				</div>
			) : (
				<ResultErrorDisplay state={resultState} />
			)}
		</div>
	);
}

export default AgentMapComponent;
